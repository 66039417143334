import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby' 
import { Container, Row, Col, Badge } from 'reactstrap'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Section from '../components/Section'
import PricingWidgetSection from '../components/PricingWidgetSection'
import BoxedCTASection from '../components/BoxedCTASection'
import RichText from '../components/RichText'
import Link, { addLocalePrefix } from '../components/Link'
import LocaleContext from '../components/LocaleContext'

import empiraaLogosImage from '../images/empiraa/empiraa-logos.png'

import './landing-page.scss'

class HubspotForm extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: this.props.formId,
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}

export default ({data}) => {
  const page = data.contentfulLandingPage
  const { title, localePrefix, timeDate, bannerText, mainText, bannerImage, formId, className, brandLogo, brandText, webinarHost, hostImage2, hostText2, hostPosition1, hostPosition2, hostName3, hostPosition3, hostImage3, hostName4, hostPosition4, hostImage4, hostDesc1, hostDesc2, hostDesc3, hostDesc4 } = page
  const banner = page.bannerText && page.bannerText.childMarkdownRemark && page.bannerText.childMarkdownRemark.html
  const main = page.mainText && page.mainText.childMarkdownRemark && page.mainText.childMarkdownRemark.html
  const heroImageUrl = page.optionalBannerImage && page.optionalBannerImage.file && page.optionalBannerImage.file.url

  const locale = useContext(LocaleContext)

  return (
    <Layout
      className={`${className} LandingPage`}
      title={`${title} - Live`}
      locale={`en-${localePrefix.toUpperCase()}`}
    >
      <Hero
        backgroundProps={{
          style: {
            backgroundImage: heroImageUrl ? `url(${heroImageUrl})` : null
          }
        }}
      >
        {!page.optionalBannerImage &&
        <Container>
          <Row>

            {!hostName3 && !hostText2 &&
            <Col lg={7} className="pl-0 pr-sm-6 pr-xs-0 d-flex flex-column justify-content-center">
              {brandLogo &&
              <div className="brandHeader">
                <Col lg={12} xs={12} className="pr-6 pt-3 pb-4">
                  {brandLogo && brandLogo.file.url &&
                    <img src={brandLogo.file.url} />
                  }
                </Col>
              </div>}
              {title && className != "referral" &&
              <h5 className="tag"><span>{timeDate}<span className="tag-divisor"> | </span>{title}</span></h5>}
              {banner && 
              <div dangerouslySetInnerHTML={{ __html: banner }} />}
            </Col>}

            {!hostName3 && hostText2 &&
            <Col lg={7} className="pl-0 pr-sm-6 pr-xs-0 d-flex flex-column justify-content-center">
              {brandLogo &&
              <div className="brandHeader">
                <Col lg={4} xs={6} className="pr-6 pt-3 pb-4">
                  {brandLogo && brandLogo.file.url &&
                    <img src={brandLogo.file.url} />
                  }
                </Col>
                <Col lg={7} xs={6} className="pb-4">
                  <div dangerouslySetInnerHTML={{ __html: brandText }} />
                </Col>
              </div>}
              {!brandLogo && title && className != "referral" &&
              <h5 className="tag">{title} <span> | {timeDate}</span></h5>}
              {banner && 
              <div dangerouslySetInnerHTML={{ __html: banner }} />}
            </Col>}

            {hostName3 && !hostName3 &&
            <Col lg={5} className="pl-0 pr-sm-6 pr-xs-0 d-flex flex-column justify-content-center">
              {brandLogo &&
              <div className="brandHeader">
                <Col lg={4} xs={6} className="pr-6 pt-3 pb-4">
                  {brandLogo && brandLogo.file.url &&
                    <img src={brandLogo.file.url} />
                  }
                </Col>
                <Col lg={7} xs={6} className="pb-4">
                  <div dangerouslySetInnerHTML={{ __html: brandText }} />
                </Col>
              </div>}
              {!brandLogo && title && className != "referral" &&
              <h5 className="tag">{title} | {hostName3 && <span> {timeDate}</span>}</h5>}
              {banner && 
              <div dangerouslySetInnerHTML={{ __html: banner }} />}
            </Col>}
            
            {hostName4 && 
            <Col lg={7} className="pl-0 pr-sm-6 pr-xs-0 d-flex flex-column justify-content-center host4">
              <h5 className="tag">{title} | {hostName3 && <span> {timeDate}</span>}</h5>
              
              {banner && 
              <div dangerouslySetInnerHTML={{ __html: banner }} />}
              
              {brandLogo &&
              <div className="brandHeader brandHeader4">
                <Col lg={4} xs={6} className="pr-6 pt-3 pb-4">
                  {brandLogo && brandLogo.file.url &&
                    <img src={brandLogo.file.url} />
                  }
                </Col>
                <Col lg={7} xs={6} className="pb-4">
                  <div dangerouslySetInnerHTML={{ __html: brandText }} />
                </Col>
              </div>}
            </Col>}

            {!hostName3 && hostText2 &&
            <Col lg={5} className={`${hostText2 && 'has2'} d-flex flex-column align-items-end justify-content-center bannerImage left-border`}>
              <div className="d-flex flex-row align-items-center justify-content-start speakers smaller">
                {bannerImage && bannerImage.file.url &&
                  <img src={bannerImage.file.url} />
                }
                <div>
                  {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
                  {hostPosition1 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start speakers smaller">
                {hostImage2 && hostImage2.file.url &&
                  <img src={hostImage2.file.url} />
                }
                <div>
                  {hostText2 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostText2 }} />}
                  {hostPosition2 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition2 }} />}
                </div>
              </div>
            </Col>}

            {!hostName3 && !hostText2 &&
            <Col lg={5} className={`d-flex flex-column align-items-end justify-content-center bannerImage left-border`}>
              <div className="d-flex flex-row align-items-center justify-content-start speakers smaller">
                {bannerImage && bannerImage.file.url &&
                  <img src={bannerImage.file.url} />
                }
                <div>
                  {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
                  {hostPosition1 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
                </div>
              </div>
            </Col>}

            {hostName3 && !hostName4 &&
            <Col lg={7} className="d-flex flex-row justify-content-center bannerImage has3">
            <Row>
              <div className="d-flex flex-column align-items-center justify-content-start speakers right-border">
                {bannerImage && bannerImage.file.url &&
                  <img src={bannerImage.file.url} />
                }
                <div className="d-flex flex-column align-items-center">
                  {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
                  {hostPosition1 && <div className="webinarHostPosition short" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start speakers right-border">
                {hostImage2 && hostImage2.file.url &&
                  <img src={hostImage2.file.url} />
                }
                <div className="d-flex flex-column align-items-center">
                  {hostText2 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostText2 }} />}
                  {hostPosition2 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition2 }} />}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start speakers">
                {hostImage3 && hostImage3.file.url &&
                  <img src={hostImage3.file.url} />
                }
                <div className="d-flex flex-column align-items-center">
                  {hostName3 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostName3 }} />}
                  {hostPosition3 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition3 }} />}
                </div>
              </div>
            </Row>
            </Col>}
            
            {hostName4 &&
            <Col lg={5} className="d-flex flex-row justify-content-center bannerImage has4">
            <Row>
              <div className="d-flex flex-column align-items-center justify-content-start speakers">
                {bannerImage && bannerImage.file.url &&
                  <img src={bannerImage.file.url} />
                }
                <div className="d-flex flex-column align-items-center">
                  {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
                  {hostPosition1 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start speakers">
                {hostImage2 && hostImage2.file.url &&
                  <img src={hostImage2.file.url} />
                }
                <div className="d-flex flex-column align-items-center">
                  {hostText2 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostText2 }} />}
                  {hostPosition2 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition2 }} />}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start speakers">
                {hostImage3 && hostImage3.file.url &&
                  <img src={hostImage3.file.url} />
                }
                <div className="d-flex flex-column align-items-center">
                  {hostName3 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostName3 }} />}
                  {hostPosition3 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition3 }} />}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start speakers">
                {hostImage4 && hostImage4.file.url &&
                  <img src={hostImage4.file.url} />
                }
                <div className="d-flex flex-column align-items-center">
                  {hostName4 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostName4 }} />}
                  {hostPosition4 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition4 }} />}
                </div>
              </div>
            </Row>
            </Col>}
          </Row>
        </Container>}
      </Hero>

      {className=="empiraa" &&
      <Section className="py-0 empiraa-logos">
        <Container className="">
          <img src={empiraaLogosImage} />
        </Container>
      </Section>
      }

      <Section className="LandingPage__Main pt-5 pb-2">
        <Container className="py-5">
          <Row>
            {className == "referral" && formId!=null &&
            <Col lg={4} className="pr-6 pt-3 pb-4 referral-text">
              {main &&
                <div dangerouslySetInnerHTML={{ __html: main }} />
              }
            </Col>}

            {className == "referral" && formId==null &&
            <Col lg={12} className="pt-3 pb-4 referral-text-full-width">
              {main &&
                <div dangerouslySetInnerHTML={{ __html: main }} />
              }

              <div className="extraButtons">
                <Link
                  color="electric-blue"
                  type="button"
                  href="https://info.myhr.works/meetings/sam-tutton?utm_campaign=Gap&utm_source=Conference"
                  localePrefix={false}
                  className=""
                >
                  Book A Demo
                </Link>

                <Link
                  color="cyan"
                  type="button"
                  href="/nz/partners/the-gap"
                  localePrefix={false}
                  className=""
                >
                  The Gap Discount
                </Link>
              </div>
            </Col>}

            {className != "referral" &&
            <Col lg={6} className="pr-5 pb-4">
              {main &&
                <div className="pr-6" dangerouslySetInnerHTML={{ __html: main }} />
              }
            </Col>}

            {className == "referral" && formId!=null &&
            <Col lg={1} className="pb-4">
            </Col>}

            {className == "referral" && formId!=null &&
            <Col lg={7} className="pb-4">
              <HubspotForm formId={formId} />
            </Col>}

            {className != "referral" &&
            <Col lg={6} className="pb-5">
              <HubspotForm formId={formId} />
            </Col>}
          </Row>
        </Container>
      </Section>

      {!hostName3 && !hostText2 && webinarHost && hostDesc1 &&
      <Section className="LandingPage__Main py-6 text-center meet-your-hosts">
        <Container className="py-5">
          {className=="empiraa" && <h2>Meet the hosts</h2>}
          {className!="empiraa" && <h2>Meet the speakers</h2>}

          <Row className="py-4">
            <Col lg={{ size: 4, offset: 4 }} className="px-5 py-4">
              {bannerImage && bannerImage.file.url &&
                <img src={bannerImage.file.url} />
              }
              {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
              {hostPosition1 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
              {hostDesc1 && <RichText json={hostDesc1.json} />}
            </Col>
          </Row>
        </Container>
      </Section>}

      {!hostName3 && hostText2 && webinarHost && hostDesc1 &&
      <Section className="LandingPage__Main py-6 text-center meet-your-hosts">
        <Container className="py-5">
          {className=="empiraa" && <h2>Meet the hosts</h2>}
          {className!="empiraa" && <h2>Meet the speakers</h2>}

          <Row className="py-4">
            <Col lg={{ size: 4, offset: 2 }} className="px-5 py-4">
              {bannerImage && bannerImage.file.url &&
                <img src={bannerImage.file.url} />
              }
              {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
              {hostPosition1 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
              {hostDesc1 && <RichText json={hostDesc1.json} />}
            </Col>
            <Col lg={4} className="px-5 py-4">
              {hostImage2 && hostImage2.file.url &&
                <img src={hostImage2.file.url} />
              }
              {hostText2 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostText2 }} />}
              {hostPosition2 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition2 }} />}
              {hostDesc2 && <RichText json={hostDesc2.json} />}
            </Col>
          </Row>
        </Container>
      </Section>}

      {hostName3 && hostDesc1 && !hostName4 && 
      <Section className="LandingPage__Main py-6 text-center meet-your-hosts">
        <Container className="py-5">
          {className=="empiraa" && <h2>Meet the hosts</h2>}
          {className!="empiraa" && <h2>Meet the speakers</h2>}

          <Row className="py-4">
            <Col lg={4} className="px-5 py-4">
              {bannerImage && bannerImage.file.url &&
                <img src={bannerImage.file.url} />
              }
              {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
              {hostPosition1 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
              {hostDesc1 && <RichText json={hostDesc1.json} />}
            </Col>
            <Col lg={4} className="px-5 py-4">
              {hostImage2 && hostImage2.file.url &&
                <img src={hostImage2.file.url} />
              }
              {hostText2 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostText2 }} />}
              {hostPosition2 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition2 }} />}
              {hostDesc2 && <RichText json={hostDesc2.json} />}
            </Col>
            <Col lg={4} className="px-5 py-4">
              {hostImage3 && hostImage3.file.url &&
                <img src={hostImage3.file.url} />
              }
              {hostName3 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostName3 }} />}
              {hostPosition3 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition3 }} />}
              {hostDesc3 && <RichText json={hostDesc3.json} />}
            </Col>
          </Row>
        </Container>
      </Section>}
      
      {hostName4 && hostDesc1 && 
      <Section className="LandingPage__Main py-6 text-center meet-your-hosts">
        <Container className="py-5">
          {className=="empiraa" && <h2>Meet the hosts</h2>}
          {className!="empiraa" && <h2>Meet the speakers</h2>}
      
          <Row className="py-4">
            <Col lg={3} className="px-5 py-4">
              {bannerImage && bannerImage.file.url &&
                <img src={bannerImage.file.url} />
              }
              {webinarHost && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: webinarHost }} />}
              {hostPosition1 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition1 }} />}
              {hostDesc1 && <RichText json={hostDesc1.json} />}
            </Col>
            <Col lg={3} className="px-5 py-4">
              {hostImage2 && hostImage2.file.url &&
                <img src={hostImage2.file.url} />
              }
              {hostText2 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostText2 }} />}
              {hostPosition2 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition2 }} />}
              {hostDesc2 && <RichText json={hostDesc2.json} />}
            </Col>
            <Col lg={3} className="px-5 py-4">
              {hostImage3 && hostImage3.file.url &&
                <img src={hostImage3.file.url} />
              }
              {hostName3 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostName3 }} />}
              {hostPosition3 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition3 }} />}
              {hostDesc3 && <RichText json={hostDesc3.json} />}
            </Col>
            <Col lg={3} className="px-5 py-4">
              {hostImage4 && hostImage4.file.url &&
                <img src={hostImage4.file.url} />
              }
              {hostName4 && <div className="webinarHost" dangerouslySetInnerHTML={{ __html: hostName4 }} />}
              {hostPosition4 && <div className="webinarHostPosition" dangerouslySetInnerHTML={{ __html: hostPosition4 }} />}
              {hostDesc4 && <RichText json={hostDesc4.json} />}
            </Col>
          </Row>
        </Container>
      </Section>}

    </Layout>
  );
}

export const pageQuery = graphql`
  query LandingPageByUrl($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      ...ContentfulLandingPageFragment
    }
  }
`